/* You can add global styles to this file, and also import other style files */
/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2') format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: 'Public Sans';
  src: url('/assets/fonts/public-sans-italic.woff2') format('woff2'),
       url('/assets/fonts/public-sans-italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Public Sans';
  src: url('/assets/fonts/public-sans-regular.woff2') format('woff2'),
       url('/assets/fonts/public-sans-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Public Sans';
  src: url('/assets/fonts/public-sans-bold.woff2') format('woff2'),
       url('/assets/fonts/public-sans-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Public Sans';
  src: url('/assets/fonts/public-sans-semi-bold.woff2') format('woff2'),
       url('/assets/fonts/public-sans-semi-bold.woff') format('woff');
  font-weight: 600; /* Semi-bold weight */
  font-style: normal;
}

@font-face {
  font-family: 'Public Sans';
  src: url('/assets/fonts/public-sans-light.woff2') format('woff2'),
       url('/assets/fonts/public-sans-light.woff') format('woff');
  font-weight: 300; /* Light weight */
  font-style: normal;
}

@font-face {
  font-family: 'Public Sans';
  src: url('/assets/fonts/public-sans-medium.woff2') format('woff2'),
       url('/assets/fonts/public-sans-medium.woff') format('woff');
  font-weight: 500; /* Medium weight */
  font-style: normal;
}

body {
  font-family: 'Public Sans', sans-serif;
}

.bold-text {
  font-weight: bold; /* Apply bold weight */
}

.semi-bold-text {
  font-weight: 600; /* Apply semi-bold weight */
}

.light-text {
  font-weight: 300; /* Apply light weight */
}

.medium-text {
  font-weight: 500; /* Apply medium weight */
}

@import "node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
@import "node_modules/font-awesome/css/font-awesome.css";
@import 'bootstrap/scss/bootstrap.scss';
@import 'node_modules/ag-grid-community/styles/ag-grid.css';
@import 'node_modules/ag-grid-community/styles/ag-theme-alpine.css';

body {
  margin: 0;
  padding: 0;
}

* {
  margin: 0;
  padding: 0;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  font-family: "PT Sans", sans-serif;
  font-size: 1rem;
  font-weight: 300;
}

h1, .h1 {
  font-size: 2.5rem;
  line-height: 1.2;
  font-weight: 800;
}

h2, .h2 {
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: 700;
}

a {
  color: #005ea2;
  text-decoration: underline;
}

.external-link {
  color: #1352c9;
  cursor: pointer;
  overflow-wrap: anywhere;
}

.nav-container {
  .mat-tab-label-content, .mat-tab-label, .mat-tab-link {
    font-size: 16px;
    color: black;
    opacity: 1;
    text-decoration: none;
  }
  .mat-tab-nav-bar, .mat-tab-header {
    border: 0;
  }
}

/* Add this in your global styles file, not the component's CSS */
::ng-deep .mat-tab-label-active {
  color: #106cc8 !important; /* Blue color for active state */
}

::ng-deep .mat-ink-bar {
  background-color: #01778d !important; /* Specific color for the ink bar */
}


.popover {background-color: #f6f7f6;}
